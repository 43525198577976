import React, { useMemo, useState, useEffect } from 'react';
import {
    AppBar,
    Typography,
    Button,
    Container,
    Grid,
    Paper,
    TextField,
    Card,
    CardContent,
    CardMedia,
    Box,
    Divider,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import './LandingPage.css';
import '../../common/fontsize.css';
import '../../index.css';
import { RWebShare } from 'react-web-share';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ReactComponent as InstagramLogo } from '../../assets/landing-instagram-logo.svg';
import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import { ReactComponent as ElynkerLogo } from '../../assets/landing-elynker-logo.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle-icon.svg';
import { ReactComponent as QuoteIcon } from '../../assets/landing-quote.svg';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import DEFAULT_IMAGE from '../../assets/profile-image.png';
import useValidation from '../../hooks/useValidation';
import { scrollToInput } from '../../utils/helper-funtions';
import {
    COMPANY_LOGO_BASE_URL,
    CONTACT_US_ERROR,
    CONTACT_US_SUCCESS,
    DIALOG_TITLE,
    PRODUCT_IMAGE_BASE_URL,
    SOCIAL_MEDIA_IMAGE_PATH,
} from '../../utils/APIs/constants';
import {
    getUserDataByHostAndDomain,
    postContactUsApi,
} from '../../utils/APIs/endpoints';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import ReactGA from 'react-ga4';
const UserProfileImage = ({ userData }) => {
    // Extract the first two words from the name
    const getFirstTwoWords = (name) => {
        if (!name) return '';
        const words = name.split(' ');
        return words?.slice(0, 2).join(' ');
    };

    const imageUrl = userData?.registration?.image_path
        ? COMPANY_LOGO_BASE_URL + userData?.registration?.image_path
        : '';

    const displayName = userData?.registration?.name
        ? getFirstTwoWords(userData?.registration?.name)
        : '';

    return (
        <div
            style={{
                position: 'relative',

                borderRadius: '100px',
                overflow: 'hidden',
            }}
        >
            {userData?.registration?.image_path && (
                <img
                    src={imageUrl}
                    width={'96px'}
                    height={'96px'}
                    className="company-logo-img"
                    style={{ borderRadius: '100px', objectFit: 'cover' }}
                    alt="Profile"
                />
            )}

            {!userData?.registration?.image_path && displayName && (
                <div
                    className="company-logo-img"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#fff',
                        backgroundColor: '#FD5723',
                        borderRadius: '50%',

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    <h1>{displayName.slice(0, 2)}</h1>
                </div>
            )}
        </div>
    );
};

const options = {
    items: 1,
    nav: false,
    dots: true,
    loop: false,
    autoplay: false,

    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        960: {
            items: 2,
        },
        1200: {
            items: 2,
        },
    },
};

const LandingPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData } = useSelector((state) => state.user);
    const [openCallModal, setOpenCallModal] = useState(false);
    const handleOpenCallModal = () => setOpenCallModal(true);
    const handleCloseCallModal = () => setOpenCallModal(false);

    const [openErrModal, setOpenErrModal] = useState(false);
    const handleOpenErrModal = () => setOpenErrModal(true);
    const handleCloseErrModal = () => setOpenErrModal(false);
    useEffect(() => {
        ReactGA.event('Landing Page', {
            category: 'Page View',
            action: 'view',
            page_title: 'Landing Page',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, [userData]);

    const userProducts = useMemo(() => {
        return userData?.registration?.products || [];
    }, [userData]);

    const product_ids = useMemo(() => {
        return userData?.product_ids || '';
    }, [userData]);

    const sellerTestimonialDetails = useMemo(() => {
        return userData?.registration?.seller_testimonials || null;
    }, [userData]);

    const social_links = useMemo(() => {
        return userData?.registration?.social_links || [];
    }, [userData]);

    useEffect(() => {
        setshareUrl(window.location.href);
        async function fetchUserData() {
            try {
                const hostUrl = window.location.host;

                const response = await getUserDataByHostAndDomain(
                    `url=https://${hostUrl}`
                );

                if (response.status === 200 && response.data?.data) {
                    dispatch({
                        type: 'USER_SLICE/setUserData',
                        payload: response.data.data,
                    });
                }
            } catch (error) {
                if (error.status === 400) {
                    navigate('/not-found', {
                        replace: false,
                        state: {
                            status: 400,
                            message:
                                'Your profile is not approved, You can not create or manage website.',
                        },
                    });
                }
                if (error.status === 404) {
                    navigate('/not-found', {
                        replace: false,
                        state: { status: 404, message: 'Website not found.' },
                    });
                }
                if (error.status === 500) {
                    navigate('/not-found', {
                        replace: false,
                        state: { status: 404, message: 'Network issue.' },
                    });
                }
            }
        }
        fetchUserData();
    }, [window.location.host]);

    const [topProducts, setTopProducts] = React.useState([]);

    React.useEffect(() => {
        const idsArray = product_ids
            ? product_ids.split(',').map((id) => parseInt(id, 10))
            : [];
        const filteredTopProducts = userProducts.filter((item) =>
            idsArray.includes(item.id)
        );
        setTopProducts(filteredTopProducts);
    }, [product_ids, userProducts]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isTabletOnly = useMediaQuery({
        query: '(min-width: 600px) and (max-width: 1023px)',
    });
    const isMobileOnly = useMediaQuery({ query: '(max-width: 600px)' });

    // State to track if all products are shown
    const [showAll, setShowAll] = useState(false);

    const [showAllTopProduct, setShowAllTopProduct] = useState(false);
    const handleToggleShow = () => {
        setShowAll((prevShowAll) => !prevShowAll);
        // setShowViewMore(true);
    };
    const handleTopProductsToggleShow = () => {
        setShowAllTopProduct((prevShowAll) => !prevShowAll);
        // setShowViewMore(true);
    };
    const handleClick = (url) => {
        // Navigate to the provided URL
        window.open(url, '_blank');
    };
    const displayedProducts = showAll ? userProducts : userProducts.slice(0, 4);
    const displayedTopProducts = showAllTopProduct
        ? topProducts
        : topProducts.slice(0, 4);

    // const handleViewMore = () => {
    //   alert('View More button clicked!');
    // };
    const { validateInput, errors } = useValidation();
    const [contactUsForm, setContactUSForm] = React.useState({
        first_name: '',
        last_name: '',
        message: '',
        mobileNumber: '',
        email: '',
    });
    const phoneNumberPattern = /^\d{0,10}$/; // Regex pattern to match 0 to 10 digits
    const successCallback = (data) => {
        // loading(false);
        ReactGA.event('Contact Information', {
            category: 'GetInTouch',
            action: 'success',
            page_title: 'Data submitted successfuly',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
        handleOpenCallModal();
    };
    const onShare = () => {
        if (rWebRef.current) rWebRef.current.click();
    };
    const errorCallback = (message) => {
        // loading(false);
        ReactGA.event('Contact Information', {
            category: 'GetInTouch',
            action: 'error',
            page_title: 'Error while submitting data',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
        handleOpenErrModal();
    };
    const onChnageContactForm = (e) => {
        const { name, value } = e.target;

        if (name === 'mobileNumber') {
            if (!phoneNumberPattern.test(value)) {
                validateInput('mobileNumber', value);
                return;
            }
        }
        if (name === 'email') {
            validateInput('outReachEmail', value);
        } else {
            validateInput([name], value);
        }

        setContactUSForm((prev) => ({ ...prev, [name]: value }));
    };
    const contactUsSubmitSubmit = async () => {
        // loading(true);
        const registrationId = userData?.registration?.id;
        if (registrationId) {
            const details = {
                name: contactUsForm.first_name + ' ' + contactUsForm.last_name,
                phone: contactUsForm.mobileNumber,
                email: contactUsForm.email,
                comments: contactUsForm.message,
                registrationId,
            };
            try {
                const response = await postContactUsApi(details);
                ReactGA.event('Contact Information', {
                    category: 'GetInTouch',
                    action: 'click',
                    page_title: 'Landing Page',
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    ...details,
                });
                if (response) {
                    successCallback(response);
                }
            } catch (err) {
                errorCallback(err?.response);
            }
            setContactUSForm({
                first_name: '',
                last_name: '',
                message: '',
                mobileNumber: '',
                email: '',
            });
            // loading(false);
        }
    };
    const rWebRef = React.useRef(null);
    const [shareUrl, setshareUrl] = React.useState();
    const onSaveContactForm = () => {
        if (
            !contactUsForm.first_name ||
            !contactUsForm.last_name ||
            !contactUsForm.message ||
            !contactUsForm.mobileNumber ||
            !contactUsForm.email
        ) {
            if (!contactUsForm.first_name) {
                validateInput('first_name');
                scrollToInput('first_name');
            }
            if (!contactUsForm.last_name) {
                validateInput('last_name');
                scrollToInput('last_name');
            }
            if (!contactUsForm.message) {
                validateInput('message');
                scrollToInput('message');
            }
            if (!contactUsForm.mobileNumber) {
                validateInput('mobileNumber');
                scrollToInput('mobileNumber');
            }
            if (!contactUsForm.email) {
                validateInput('outReachEmail');
                scrollToInput('outReachEmail');
            }
        } else {
            contactUsSubmitSubmit(contactUsForm);
        }
    };
    return (
        <div className="root">
            <AppBar position="static" className="header-appbar">
                <Typography
                    style={{ marginLeft: 40 }}
                    className="fs-22  text-darkgrey line-height-26 font-skmodernist fw-bold"
                >
                    {userData?.registration?.company_name}
                </Typography>
            </AppBar>

            {/* <main> */}
            <Container className="custom-container">
                <Dialog
                    open={openCallModal}
                    onClose={handleCloseCallModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                {DIALOG_TITLE}
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseCallModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {CONTACT_US_SUCCESS}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button
                            onClick={() => {
                                handleCloseCallModal();
                            }}
                            className="btn-save"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Error Dialog */}
                <Dialog
                    open={openErrModal}
                    onClose={handleCloseErrModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                {DIALOG_TITLE}
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseErrModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {CONTACT_US_ERROR}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button
                            onClick={() => {
                                handleCloseErrModal();
                            }}
                            className="btn-save"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box className="hero-content">
                    {isDesktopOrLaptop && (
                        <>
                            <Box
                                className="position-relative d-flex w-100"
                                sx={{
                                    borderBottom: '1px solid #E3E3E3',
                                    paddingBottom: '50px',
                                }}
                            >
                                <Box className="company-logo">
                                    <UserProfileImage userData={userData} />

                                    {/* <img
                    src={
                      userData?.registration?.image_path
                        ? COMPANY_LOGO_BASE_URL +
                          userData?.registration?.image_path
                        : require('../../assets/profile-image.png')
                    }
                    width={'96px'}
                    height={'96px'}
                    style={{ borderRadius: 100 }}
                  ></img> */}
                                </Box>
                                <Box className="w-100">
                                    <Box className="d-flex justify-content-between w-100">
                                        <Typography className="fs-22 text-darkgrey line-height-26 font-skmodernist fw-bold text-start">
                                            {
                                                userData?.registration
                                                    ?.company_name
                                            }{' '}
                                            <br />
                                            {/* {userData?.user?.email} <br /> */}
                                            {/* {userData?.user?.country_code}{' '}
                      {userData?.user?.mobile_number} */}
                                        </Typography>
                                        <div
                                            className="share-button"
                                            onClick={onShare}
                                        >
                                            <ShareOutlinedIcon
                                                sx={{ color: '#393939' }}
                                            />
                                        </div>
                                    </Box>
                                    <Typography className="fs-15 font-skmodernist text-gray text-start">
                                        {userData?.registration?.city || ''}
                                    </Typography>

                                    <Typography className="fs-14 font-skmodernist text-secondary text-start">
                                        Owned by{' '}
                                        {userData?.registration?.name || ''}
                                    </Typography>

                                    <Box
                                        className="d-flex "
                                        sx={{ gap: '60px', marginTop: '24px' }}
                                    >
                                        {/* <Box
                      className="d-flex align-items-center"
                      sx={{ gap: '10px' }}
                    >
                      <InstagramLogo width={27} height={27} />
                      <Box>
                        <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                          2.2 M
                        </Typography>
                        <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                          Followers
                        </Typography>
                      </Box>
                    </Box> */}

                                        <Box
                                            className="d-flex align-items-center"
                                            sx={{ gap: '10px' }}
                                        >
                                            {/* <GoogleLogo width={27} height={27} /> */}
                                            <Box>
                                                <Box
                                                    className="d-flex align-items-center"
                                                    gap={'5px'}
                                                >
                                                    <GradeRoundedIcon
                                                        sx={{
                                                            color: '#FFCB01',
                                                            width: '18px',
                                                            height: '18px',
                                                        }}
                                                    />
                                                    <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                                                        {
                                                            userData
                                                                ?.registration
                                                                ?.rating
                                                        }
                                                    </Typography>
                                                </Box>

                                                {/* <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                          2450232 Reviews
                        </Typography> */}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}

                    {isTabletOnly && (
                        <>
                            <Box
                                className="position-relative d-flex w-100"
                                sx={{
                                    borderBottom: '1px solid #E3E3E3',
                                    paddingBottom: '50px',
                                }}
                            >
                                <Box className="company-logo">
                                    {/* <img
                    src={
                      userData?.registration?.image_path
                        ? COMPANY_LOGO_BASE_URL +
                          userData?.registration?.image_path
                        : require('../../assets/profile-image.png')
                    }
                    width={'96px'}
                    height={'96px'}
                    style={{ borderRadius: 100 }}
                  ></img> */}
                                    <UserProfileImage userData={userData} />
                                </Box>
                                <Box className="w-100">
                                    <Box className="d-flex justify-content-between w-100">
                                        <Typography className="fs-22 text-darkgrey line-height-26 font-skmodernist fw-bold text-start">
                                            {
                                                userData?.registration
                                                    ?.company_name
                                            }
                                        </Typography>
                                        <div
                                            className="share-button"
                                            onClick={onShare}
                                        >
                                            <ShareOutlinedIcon
                                                sx={{ color: '#393939' }}
                                            />
                                        </div>
                                    </Box>
                                    <Typography className="fs-15 font-skmodernist text-gray text-start">
                                        {userData?.registration?.city || ''}
                                    </Typography>

                                    <Typography className="fs-14 font-skmodernist text-secondary text-start">
                                        Owned by{' '}
                                        {userData?.registration?.name || ''}
                                    </Typography>

                                    <Box
                                        className="d-flex "
                                        sx={{ gap: '60px', marginTop: '24px' }}
                                    >
                                        <Box
                                            className="d-flex align-items-center"
                                            sx={{ gap: '10px' }}
                                        >
                                            <InstagramLogo
                                                width={27}
                                                height={27}
                                            />
                                            <Box>
                                                <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                                                    {
                                                        userData?.registration
                                                            ?.rating
                                                    }
                                                </Typography>
                                                <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                                                    Followers
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {/* <Box
                      className="d-flex align-items-center"
                      sx={{ gap: '10px' }}
                    >
                      <GoogleLogo width={27} height={27} />
                      <Box>
                        <Box className="d-flex align-items-center" gap={'5px'}>
                          <GradeRoundedIcon
                            sx={{
                              color: '#FFCB01',
                              width: '18px',
                              height: '18px',
                            }}
                          />
                          <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                            {userData?.registration?.rating}
                          </Typography>
                        </Box>

                        {/* <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                          2450232 Reviews
                        </Typography> */}
                                        {/* </Box> */}
                                        {/* </Box>  */}
                                        <Box
                                            className="d-flex align-items-center"
                                            sx={{ gap: '10px' }}
                                        >
                                            {/* <GoogleLogo width={27} height={27} /> */}
                                            <Box>
                                                <Box
                                                    className="d-flex align-items-center"
                                                    gap={'5px'}
                                                >
                                                    <GradeRoundedIcon
                                                        sx={{
                                                            color: '#FFCB01',
                                                            width: '18px',
                                                            height: '18px',
                                                        }}
                                                    />
                                                    <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                                                        {
                                                            userData
                                                                ?.registration
                                                                ?.rating
                                                        }
                                                    </Typography>
                                                </Box>

                                                {/* <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                          2450232 Reviews
                        </Typography> */}
                                            </Box>
                                        </Box>
                                        {/* <Box className="text-start">
                      <Typography className="fs-14 font-gotham text-darkgrey">
                        Powered by
                      </Typography>
                      <ElynkerLogo width={55} height={16} />
                    </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}

                    {isMobileOnly && (
                        <>
                            <Box
                                className="position-relative d-flex w-100"
                                sx={{
                                    borderBottom: '1px solid #E3E3E3',
                                    paddingBottom: '21px',
                                }}
                            >
                                <Box className="company-logo">
                                    {/* <img
                    src={
                      userData?.registration?.image_path
                        ? COMPANY_LOGO_BASE_URL +
                          userData?.registration?.image_path
                        : require('../../assets/profile-image.png')
                    }
                    width={'96px'}
                    height={'96px'}
                    style={{ borderRadius: 100 }}
                  ></img> */}
                                    <UserProfileImage userData={userData} />
                                </Box>
                                <Box className="w-100">
                                    <Box className="d-flex justify-content-between w-100 align-items-center">
                                        <Typography className="fs-20 text-darkgrey line-height-26 font-skmodernist fw-bold text-start">
                                            {
                                                userData?.registration
                                                    ?.company_name
                                            }
                                        </Typography>
                                        <div
                                            className="share-button"
                                            onClick={onShare}
                                        >
                                            <ShareOutlinedIcon
                                                sx={{ color: '#393939' }}
                                            />
                                        </div>
                                    </Box>
                                    <Typography className="fs-14 font-skmodernist text-gray text-start">
                                        {userData?.registration?.city || ''}
                                    </Typography>

                                    <Typography className="fs-13 font-skmodernist text-secondary text-start">
                                        Owned by{' '}
                                        {userData?.registration?.name || ''}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                className="d-flex justify-content-between"
                                sx={{
                                    borderBottom: '1px solid #E3E3E3',
                                    padding: '16px 0px',
                                }}
                            >
                                {/* <Box className="d-flex align-items-center" sx={{ gap: '10px' }}>
                  <InstagramLogo width={27} height={27} />
                  <Box>
                    <Typography className="fs-13 font-gotham fw-bold text-start text-darkgrey">
                      {userData?.registration?.rating}
                    </Typography>
                    <Typography className="fs-12 font-skmodernist text-start text-darkgrey">
                      Followers
                    </Typography>
                  </Box>
                </Box>

                <Box className="d-flex align-items-center" sx={{ gap: '10px' }}>
                  <GoogleLogo width={27} height={27} />
                  <Box>
                    <Box className="d-flex align-items-center" gap={'5px'}>
                      <GradeRoundedIcon
                        sx={{ color: '#FFCB01', width: '18px', height: '18px' }}
                      />
                      <Typography className="fs-13 font-gotham fw-bold text-start text-darkgrey">
                        {userData?.registration?.rating}
                      </Typography>
                    </Box>

                    <Typography className="fs-12 font-skmodernist text-start text-darkgrey">
                      2450232 Reviews
                    </Typography>
                  </Box>
                </Box> */}
                                <Box
                                    className="d-flex align-items-center"
                                    sx={{ gap: '10px' }}
                                >
                                    {/* <GoogleLogo width={27} height={27} /> */}
                                    <Box>
                                        <Box
                                            className="d-flex align-items-center"
                                            gap={'5px'}
                                        >
                                            <GradeRoundedIcon
                                                sx={{
                                                    color: '#FFCB01',
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                            />
                                            <Typography className="fs-14 font-gotham fw-bold text-start text-darkgrey">
                                                {userData?.registration?.rating}
                                            </Typography>
                                        </Box>

                                        {/* <Typography className="fs-13 font-skmodernist text-start text-darkgrey">
                          2450232 Reviews
                        </Typography> */}
                                    </Box>
                                </Box>
                                {/* <Box className="text-start">
                  <Typography className="fs-13 font-gotham text-darkgrey">
                    Powered by
                  </Typography>
                  <ElynkerLogo width={48} height={14} />
                </Box> */}
                            </Box>
                        </>
                    )}
                </Box>
            </Container>

            <Container className="custom-container">
                <Box className="text-end d-flex justify-content-end align-items-center">
                    <Typography className="fs-14 font-gotham text-darkgrey me-2">
                        Powered by
                    </Typography>
                    <ElynkerLogo width={55} height={16} />
                </Box>
                <Grid container columnSpacing={4} className="about-company-sec">
                    <Grid item md={6} lg={6} xs={12}>
                        <Typography className="header-title mb-3">
                            About Us
                        </Typography>
                        <Typography className="text-para text-justify">
                            {userData?.registration?.about_company || ''}
                        </Typography>
                    </Grid>
                    <Grid item md={6} lg={6} xs={12}>
                        <Typography className="header-title mb-3">
                            Salient Features
                        </Typography>
                        <Box className="check-para-main">
                            {/* <div className="check-para-sec">
                <CheckCircleIcon
                  style={{
                    marginRight: '12px',
                    marginTop: '3px',
                  }}
                />
                <Typography variant="body2" className="check-para">
                  Deliver perfect solution for business
                </Typography>
              </div> */}
                            {userData?.registration?.additional_detail1 && (
                                <div className="d-flex align-items-center mb-1">
                                    <CheckCircleIcon
                                        style={{
                                            marginRight: '12px',
                                            marginTop: '3px',
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="fs-14 text-darkgrey font-skmodernist"
                                    >
                                        {
                                            userData?.registration
                                                ?.additional_detail1
                                        }
                                    </Typography>
                                </div>
                            )}
                            {userData?.registration?.additional_detail2 && (
                                <div className="d-flex align-items-center mb-1">
                                    <CheckCircleIcon
                                        style={{
                                            marginRight: '12px',
                                            marginTop: '3px',
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="fs-14 text-darkgrey font-skmodernist"
                                    >
                                        {
                                            userData?.registration
                                                ?.additional_detail2
                                        }
                                    </Typography>
                                </div>
                            )}
                            {userData?.registration?.additional_detail3 && (
                                <div className="d-flex align-items-center mb-1">
                                    <CheckCircleIcon
                                        style={{
                                            marginRight: '12px',
                                            marginTop: '3px',
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="fs-14 text-darkgrey font-skmodernist"
                                    >
                                        {
                                            userData?.registration
                                                ?.additional_detail3
                                        }
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Divider className="landing-main-divider" />

            <Container className="custom-container">
                <Box className="popular-products-sec">
                    <Box className="mb-4 d-flex justify-content-between align-items-center">
                        <Typography className="header-title mb-4">
                            Top Products
                        </Typography>

                        {topProducts && topProducts.length > 4 && (
                            <Typography
                                className="fs-15 text-secondary font-skmodernist"
                                onClick={handleTopProductsToggleShow}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {showAllTopProduct ? 'See Less' : 'See All'}{' '}
                                <ChevronRightRoundedIcon />
                            </Typography>
                        )}
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        className="popular-products-slider"
                    >
                        {displayedTopProducts &&
                            displayedTopProducts.map((datas) => (
                                <>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Card className="popular-products-card">
                                            <CardMedia
                                                className="cardMedia"
                                                image={
                                                    PRODUCT_IMAGE_BASE_URL +
                                                    datas.default_image
                                                }
                                                title="Image title"
                                            />
                                            <CardContent className="cardContent">
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    className="product-title"
                                                >
                                                    {datas?.title}
                                                </Typography>

                                                <Typography className="product-subtitle">
                                                    ₹ {datas?.budget}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            ))}
                    </Grid>
                    {/* {showAllTopProduct && (
            <Box mt={4} textAlign="center">
              <Button
                variant="contained"
                onClick={handleViewMore}
                className="view-more-button"
              >
                View More
              </Button>
            </Box>
          )} */}
                </Box>
            </Container>

            <Container className="custom-container">
                <Box className="popular-products-sec pt-0">
                    <Box className="mb-4 d-flex justify-content-between align-items-center">
                        <Typography className="header-title">
                            Catalog
                        </Typography>

                        {userProducts && userProducts.length > 4 && (
                            <Typography
                                className="fs-15 text-secondary font-skmodernist"
                                onClick={handleToggleShow}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {showAll ? 'See Less' : 'See All'}{' '}
                                <ChevronRightRoundedIcon />
                            </Typography>
                        )}
                    </Box>

                    <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={4}
                        className=""
                    >
                        {displayedProducts.map((product) => (
                            <Grid item xs={6} sm={4} md={3} key={product.id}>
                                <Card className="popular-products-card">
                                    <CardMedia
                                        className="cardMedia"
                                        image={
                                            PRODUCT_IMAGE_BASE_URL +
                                            product.default_image
                                        }
                                        title={product.title}
                                    />
                                    <CardContent className="cardContent">
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            className="product-title"
                                        >
                                            {product.title}
                                        </Typography>

                                        <Typography className="product-subtitle">
                                            {product.budget}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {/* {showAll && (
            <Box mt={4} textAlign="center">
              <Button
                variant="contained"
                onClick={handleViewMore}
                className="view-more-button"
              >
                View More
              </Button>
            </Box>
          )} */}
                </Box>
            </Container>

            <Divider className="landing-main-divider" />

            <Container className="custom-container">
                <Box className="social-media-sec">
                    <Typography className="header-title mb-4">
                        Social Media
                    </Typography>
                    <div className="social-icons">
                        {social_links &&
                            social_links.map((s) => (
                                <a
                                    href={s.social_url}
                                    key={s.id}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => handleClick(s.social_url)}
                                >
                                    <img
                                        src={`${SOCIAL_MEDIA_IMAGE_PATH}/${s?.social_media_master?.media_image_path}`}
                                        alt={s.social_name}
                                        style={{
                                            width: '38px',
                                            height: '38px',
                                        }}
                                    />
                                </a>
                            ))}
                    </div>
                </Box>
            </Container>

            <Container className="custom-container">
                <Box className="contact-us-sec">
                    <Typography className="header-title mb-4">
                        Contact Us
                    </Typography>
                    <Grid item md={12} lg={12} xs={12}>
                        <Paper className="get-in-touch-bg">
                            <form noValidate autoComplete="off">
                                <Grid container spacing={3.5}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            value={contactUsForm.first_name}
                                            onChange={onChnageContactForm}
                                            fullWidth
                                        />
                                        <FormHelperText
                                            sx={{ color: 'red' }}
                                            id="company-name-helper-text"
                                        >
                                            {errors.first_name}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="last_name"
                                            name="last_name"
                                            value={contactUsForm.last_name}
                                            onChange={onChnageContactForm}
                                            label="Last Name"
                                            fullWidth
                                        />
                                        <FormHelperText
                                            sx={{ color: 'red' }}
                                            id="company-name-helper-text"
                                        >
                                            {errors.last_name}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            onChange={onChnageContactForm}
                                            label="Phone"
                                            value={contactUsForm.mobileNumber}
                                            fullWidth
                                        />
                                        <FormHelperText
                                            sx={{ color: 'red' }}
                                            id="company-name-helper-text"
                                        >
                                            {errors.mobileNumber}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="email"
                                            name="email"
                                            onChange={onChnageContactForm}
                                            value={contactUsForm.email}
                                            label="Email"
                                            fullWidth
                                        />
                                        <FormHelperText
                                            sx={{ color: 'red' }}
                                            id="company-name-helper-text"
                                        >
                                            {errors.outReachEmail}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            required
                                            id="message"
                                            name="message"
                                            value={contactUsForm.message}
                                            label="Message"
                                            onChange={onChnageContactForm}
                                            multiline
                                            rows={4}
                                            fullWidth
                                        />
                                        <FormHelperText
                                            sx={{ color: 'red' }}
                                            id="company-name-helper-text"
                                        >
                                            {errors.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} className="text-center">
                                        <Button
                                            variant="contained"
                                            onClick={onSaveContactForm}
                                            className="get-intouch-btn"
                                        >
                                            Get in Touch
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Box>
            </Container>

            <Container className="custom-container">
                <Box className="landing-testimonials-sec">
                    <Typography className="header-title mb-4">
                        Testimonials
                    </Typography>
                    {sellerTestimonialDetails?.length == 1 && (
                        <OwlCarousel
                            className="d-flex justify-content-center testimonial-single-carousel"
                            options={options}
                        >
                            {sellerTestimonialDetails &&
                                sellerTestimonialDetails?.map(
                                    (testimonial, index) => (
                                        <Paper
                                            key={index}
                                            className="feedback-paper"
                                            elevation={3}
                                        >
                                            <Typography
                                                paragraph
                                                className="testimonial-feedback"
                                            >
                                                {testimonial.testimonial}
                                            </Typography>
                                            <div className="d-flex justify-content-between ">
                                                <div className="">
                                                    <Typography
                                                        variant="h6"
                                                        className="testimonial-name"
                                                    >
                                                        {
                                                            testimonial.client_name
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="testimonial-role"
                                                    >
                                                        {
                                                            testimonial.designation
                                                        }
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <QuoteIcon className="quote-icon" />
                                                </div>
                                            </div>
                                        </Paper>
                                    )
                                )}
                        </OwlCarousel>
                    )}
                    {sellerTestimonialDetails?.length > 1 && (
                        <OwlCarousel options={options}>
                            {sellerTestimonialDetails &&
                                sellerTestimonialDetails?.map(
                                    (testimonial, index) => (
                                        <Paper
                                            key={index}
                                            className="feedback-paper"
                                            elevation={3}
                                        >
                                            <Typography
                                                paragraph
                                                className="testimonial-feedback"
                                            >
                                                {testimonial.testimonial}
                                            </Typography>
                                            <div className="d-flex justify-content-between ">
                                                <div className="">
                                                    <Typography
                                                        variant="h6"
                                                        className="testimonial-name"
                                                    >
                                                        {
                                                            testimonial.client_name
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="testimonial-role"
                                                    >
                                                        {
                                                            testimonial.designation
                                                        }
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <QuoteIcon className="quote-icon" />
                                                </div>
                                            </div>
                                        </Paper>
                                    )
                                )}
                        </OwlCarousel>
                    )}
                </Box>
            </Container>

            {/* <Container className="custom-container">
        <Grid container columnSpacing={8} className="policy-sec">
          <Grid item md={6} lg={6} xs={12}>
            <Typography className="header-title mb-3">
              Cancelation Policy
            </Typography>

            <List className="policy-list">
              <ListItem disableGutters className="list-item">
                <ListItemText
                  className="list-item-text"
                  primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the"
                />
              </ListItem>
              <ListItem disableGutters className="list-item">
                <ListItemText
                  className="list-item-text"
                  primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6} lg={6} xs={12}>
            <Typography className="header-title mb-3">
              Privacy Policy
            </Typography>
            <List className="policy-list">
              <ListItem disableGutters className="list-item">
                <ListItemText
                  className="list-item-text"
                  primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the"
                />
              </ListItem>
              <ListItem disableGutters className="list-item">
                <ListItemText
                  className="list-item-text"
                  primary="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container> */}

            {/* </main> */}

            <div className="landing-footer">
                <div className="landing-footer-top">
                    <Container className="footer-container custom-container">
                        <Typography
                            className="footer-title brand-title"
                            variant="h6"
                            gutterBottom
                        >
                            Contact Information
                        </Typography>
                        <div className="footer-flex">
                            <Typography className="footer-text" variant="body2">
                                {userData?.registration?.address1
                                    ? `${userData?.registration?.address1}`
                                    : ''}
                                {userData?.registration?.address1 ? `, ` : ''}
                                {userData?.registration?.address2
                                    ? `${userData?.registration?.address2}`
                                    : ''}
                                {userData?.registration?.address2 ? `, ` : ''}
                                {userData?.registration?.city
                                    ? `${userData?.registration?.city}`
                                    : ''}
                                {userData?.registration?.city ? `, ` : ''}
                                {userData?.registration?.state
                                    ? `${userData?.registration?.state} `
                                    : ''}
                                {userData?.registration?.pincode
                                    ? `- ${userData?.registration?.pincode}`
                                    : ''}
                            </Typography>
                            <Typography className="footer-text" variant="body2">
                                {userData?.registration?.user?.email}
                            </Typography>
                            <Typography className="footer-text" variant="body2">
                                {userData?.registration?.user?.mobile_number}
                            </Typography>
                        </div>
                    </Container>
                </div>

                <div className="landing-footer-bottom">
                    <Container className="footer-container custom-container">
                        <Typography variant="body2" className="text-copyright">
                            © Copyright 2024. all rights reserved.
                        </Typography>
                    </Container>
                </div>
            </div>
            {shareUrl && (
                <RWebShare
                    data={{
                        text: '',
                        url: shareUrl,
                        title: 'Share',
                    }}
                    onClick={() => {
                        // getCardImage();
                        // postBuisnessCard()
                    }}
                >
                    <Button ref={rWebRef} />
                </RWebShare>
            )}
        </div>
    );
};

export default LandingPage;
